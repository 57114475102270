import React, { useState } from "react"

import { useForm } from "react-hook-form"
import styled from "styled-components"
import { Headline } from "../../common/headline"
import { Link } from "gatsby-plugin-react-intl"
import { MdEmail } from "react-icons/md"
import { GiAutoRepair } from "react-icons/gi"
import { IoMdCall } from "react-icons/io"

import { AiTwotoneStar } from "react-icons/ai"
import { ThankYouMessage } from "../forms/repair-form/new"

const Container = styled.div`
  width: 100%;
  background: #cccccc;
`

const Content = styled.div`
  max-width: 1400px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  padding: 2rem;
  place-items: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Form = styled.form`
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const Input = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  margin: 9px 0;
  &:focus {
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  &::placeholder {
    text-align: center;
  }
`
const Checkbox = styled(Input)`
  height: 20px;
  margin: 0.2rem 0.5rem 0 0;
  width: 30px;
`

const Label = styled.div`
  font-family: "Open Sans";
  font-size: 1.2rem;
  margin: 0rem auto;
`

const Textarea = styled.textarea`
  width: 100%;
  height: 90px;
  border: none;
  border-radius: 5px;
  margin: 9px 0;
  resize: none;
  &:focus {
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
`

const Button = styled.input`
  font-size: 1.5rem;
  font-family: "Open Sans";
  border: none;
  height: 50px;
  width: 100%;
  background: black;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    background: #ed6523;
  }
`

const CheckboxHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 18px 0;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #f46d28;
  margin-left: 6px;
`

const Text = styled.p`
  font-family: "Open Sans";
  margin: 0.5rem 0;
`

const Fieldset = styled.fieldset`
  padding: 2rem;
  border: 1px solid #f26a28;
  border-radius: 5px;
`

const Legend = styled.legend`
  font-size: 1rem;
  font-family: "Open Sans";
  font-weight: 300;
`

const ContactForm = () => {
  const { register, errors, handleSubmit } = useForm()
  const [isSent, setIsSent] = useState(false)
  const [text, setText] = useState("Senden")
  const [button, setButton] = useState("")

  //
  const submit = async data => {
    setText("Warten...")
    setButton("value")

    await fetch("https://email-server-liart.vercel.app/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => setIsSent(true))
      .catch(() => console.log("There was an error, please try again"))
  }

  return (
    <Container>
      <Content>
        <Info>
          <Fieldset>
            <Legend>Adresse</Legend>
            <Label>TMC-TEC Reparaturservice</Label>
            <Text>Innsbrucker Platz 4</Text>
            <Text>10827 Berlin</Text>
          </Fieldset>
          <Fieldset>
            <Legend>Kontakt</Legend>
            <Text>
              {" "}
              <IoMdCall /> 030 / 856 12349
            </Text>
            <Text>
              <MdEmail /> info@tmc-home.de
            </Text>
            <Text
              style={{
                fontWeight: 100,
                textAlign: "left",
                fontSize: "1rem",
              }}
            >
              Reparaturstatus
            </Text>
            <Text>
              {" "}
              <GiAutoRepair /> reparatur@tmc-home.de
            </Text>
          </Fieldset>
        </Info>
        {isSent ? (
          <ThankYouMessage />
        ) : (
          <Form onSubmit={handleSubmit(submit)}>
            <Headline
              type="h1"
              textAlign="center"
              fontSize="2.1rem"
              margin="auto 0 0 0"
            >
              Kontakt
            </Headline>
            <Label>
              <label htmlFor="name">
                Name
                <AiTwotoneStar
                  size={10}
                  color="red"
                  style={{ verticalAlign: "text-top", margin: "0" }}
                />
                {errors.name && (
                  <span style={{ color: "red" }}>Dies ist erforderlich</span>
                )}
                <Input
                  name="name"
                  placeholder="   Vollständiger Name"
                  ref={register({ required: true, maxLength: 20 })}
                />
              </label>
            </Label>

            <Label>
              <label htmlFor="email">
                E-Mail
                <AiTwotoneStar
                  size={10}
                  color="red"
                  style={{ verticalAlign: "text-top", margin: "0" }}
                />
                {errors.email && (
                  <span style={{ color: "red" }}>Dies ist erforderlich</span>
                )}
                <Input
                  name="email"
                  placeholder="   E-Mail"
                  ref={register({ required: true, maxLength: 50 })}
                />
              </label>
            </Label>
            <Label>
              <label htmlFor="message">
                Ihr Anliegen
                <AiTwotoneStar
                  size={10}
                  color="red"
                  style={{ verticalAlign: "text-top", margin: "0" }}
                />
                {errors.message && (
                  <span style={{ color: "red" }}>Dies ist erforderlich</span>
                )}
                <Textarea
                  name="message"
                  placeholder="   Ihre Mitteilung"
                  ref={register({ required: true })}
                />
              </label>
            </Label>
            <Label>
              <label htmlFor="data">
                Dateien hochladen
                <Input name="data" type="file" multiple ref={register()} />
              </label>
            </Label>
            {errors.accept && (
              <span style={{ color: "red" }}>Dies ist erforderlich</span>
            )}
            <CheckboxHolder>
              <Checkbox
                type="checkbox"
                name="accept"
                ref={register({ required: true })}
              />

              <label htmlFor="agb">
                Ich bestätige, dass ich die Informationen zum
                <StyledLink to="/privacy">Datenschutz</StyledLink> gelesen und
                akzeptiert habe.
              </label>
            </CheckboxHolder>
            <Button type="submit" value={text} disabled={button ? true : ""} />
          </Form>
        )}
      </Content>
    </Container>
  )
}

export default ContactForm
